import { render, staticRenderFns } from "./CustomerDetailsSection.vue?vue&type=template&id=635752fd&"
import script from "./CustomerDetailsSection.vue?vue&type=script&lang=js&"
export * from "./CustomerDetailsSection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src635334155/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('635752fd')) {
      api.createRecord('635752fd', component.options)
    } else {
      api.reload('635752fd', component.options)
    }
    module.hot.accept("./CustomerDetailsSection.vue?vue&type=template&id=635752fd&", function () {
      api.rerender('635752fd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/app/js/vuejs/components/organization/customer-management/customer-details/CustomerDetailsSection.vue"
export default component.exports