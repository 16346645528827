import { render, staticRenderFns } from "./AttachFiles.vue?vue&type=template&id=2a32042f&"
import script from "./AttachFiles.vue?vue&type=script&lang=js&"
export * from "./AttachFiles.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src635334155/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2a32042f')) {
      api.createRecord('2a32042f', component.options)
    } else {
      api.reload('2a32042f', component.options)
    }
    module.hot.accept("./AttachFiles.vue?vue&type=template&id=2a32042f&", function () {
      api.rerender('2a32042f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/app/js/vuejs/components/cloud-manager/AttachFiles.vue"
export default component.exports